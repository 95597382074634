export default function DeepLinkHandler() {
    if(checkKeywordAndConvertToDeepLink("emailConfirmation")){
        return (
            <p>Go to Hyper Bomber to confirm email</p>
        )
    }

    if(checkKeywordAndConvertToDeepLink("passwordReset")){
        return (
            <p>Go to Hyper Bomber to reset password</p>
        )
    }

    if(checkKeywordAndConvertToDeepLink("playerProfile")){
        return (
            <p>Go to Hyper Bomber to see profile</p>
        )
    }

    if(checkKeywordAndConvertToDeepLink("mapShare")){
        return (
            <p>Go to Hyper Bomber to see map</p>
        )
    }

    return (
      <p></p>
    )
  }

  const checkKeywordAndConvertToDeepLink = (keyword) => {
    const pathname = window.location.href;
    const keywordIndex = pathname.indexOf(keyword);
  
    if (keywordIndex !== -1) {
      const result = pathname.substring(keywordIndex);
      const urlToOpen = "hyperbomber://" + result;
      window.open(urlToOpen, "_self");
      return true;
    }
    return false;
  }